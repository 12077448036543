import CryptoJS from "crypto-js";

export const isLoggedIn = () => {
  let data = localStorage.getItem("data");
  if (data != null) return true;
  else return false;
};

export const doLogin = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  localStorage.setItem("data", encrypted);
};

export const loginFilepath = (file) => localStorage.setItem("filepath", file);

export const getCurrentUserDetail = () => {
  if (isLoggedIn()) {
    return decryptData("data");
  } else {
    return undefined;
  }
};

export const updateData = (newData) => {
  const userData = getCurrentUserDetail();
  if (newData) {
    doLogin({
      ...userData,
      firstName: newData?.firstName,
      lastName: newData?.lastName,
      email: newData?.email,
      phoneNumber: newData?.phoneNumber,
    });
    return true;
  }
  return false;
};

const decryptData = (name) => {
  const encrypted = localStorage.getItem(name);
  try {
    const decrypted =
      encrypted &&
      CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
  } catch (error) {
    doLogout();
  }
};

export const getToken = () => {
  let data = decryptData("data");
  return data ? data?.token : null;
};

export const doLogout = () => {
  localStorage.clear();
  localStorage.removeItem("state");
};

export const setReduxState = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  localStorage.setItem("state", encrypted);
};

export const getReduxState = (name) => {
  const encrypted = localStorage.getItem(name);
  try {
    const decrypted =
      encrypted &&
      CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);

    if (decrypted === null) return undefined;
    return JSON.parse(decrypted);
  } catch (error) {
    doLogout();
  }
};

export const getTheme = () => {
  let theme = localStorage.getItem("darkMode");
  return theme;
};

export const getRole = () => {
  let data = decryptData("data");
  return data?.role?.roleName;
};
