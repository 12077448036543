const API_URL_AUTH = process.env.REACT_APP_API_URL + "/api";

export const API_URLS = {
  AUTH: {
    LOGIN: `${API_URL_AUTH}/auth/login`,
    RESET_PASSWORD: `${API_URL_AUTH}/auth/reset-password`,
    UPDATE_PROFILE: `${API_URL_AUTH}/auth/profile-update`,
    STUDENT_LIST: `${API_URL_AUTH}/auth/admin-student-list`,
  },
  USER: {
    USER_LIST: `${API_URL_AUTH}/user`,
    USER_ADD: `${API_URL_AUTH}/user`,
    USER_UPDATE: `${API_URL_AUTH}/user`,
  },
  GAMES: `${API_URL_AUTH}/games`,
  ADD_YOUTUBE_LINK: `${API_URL_AUTH}/auth/add-video`,
  ADD_IMAGE: `${API_URL_AUTH}/auth/add-image`,
  DELETE_IMAGE: `${API_URL_AUTH}/auth/delete-image`,
  FILE_LIST: `${API_URL_AUTH}/auth/files-list`,

  FEEDBACK: `${API_URL_AUTH}/feedback`,
  VOLUNTEER: {
    VOLUNTEER_LIST: `${API_URL_AUTH}/volunteer`,
  },
  QUESTION: {
    ADD_QUESTIONS: `${API_URL_AUTH}/games/add-questions`,
    QUESTION_LIST: `${API_URL_AUTH}/games/questions`,
  },
  ACTIVITY: {
    ACTIVITY_LIST: `${API_URL_AUTH}/activity`,
    CREATE_GAMEZONE_REPORT: `${API_URL_AUTH}/activity/create-gamezone-report`,
    GENERATE_GAMEZONE_REGISTRATION_REPORT: `${API_URL_AUTH}/activity/generate-activity-registration-report`,
    CREATE_GAMEZONE_REGISTRATION_REPORT: `${API_URL_AUTH}/activity/create-gamezone-registration-report`,
    ACTIVITY_ADD: `${API_URL_AUTH}/activity`,
    ACTIVITY_UPDATE: `${API_URL_AUTH}/activity`,
    AGE_MASTER: {
      GET: `${API_URL_AUTH}/auth/gamezone-details`,
      UPDATE: `${API_URL_AUTH}/auth/update-age`,
    },
    ZONE_DATE_MASTER: {
      GET: `${API_URL_AUTH}/auth/gamezone-details`,
      UPDATE: `${API_URL_AUTH}/auth/update-date`,
    },
    ENTRY_FEE_MASTER: {
      GET: `${API_URL_AUTH}/auth/gamezone-details`,
      UPDATE: `${API_URL_AUTH}/auth/update-fee`,
    },
    ZONE_SESSION: {
      GET: `${API_URL_AUTH}/auth/slots`,
      ADD: `${API_URL_AUTH}/auth/add-slot`,
      DELETE: `${API_URL_AUTH}/auth/delete-slot`,
    },
  },
  REPORT: {
    GAME_ZONE_REGISTER: `${API_URL_AUTH}/activity/gamezone-registration-report`,
    GAME_ZONE: `${API_URL_AUTH}/activity/gamezone-report`,
    ACTIVITY: `${API_URL_AUTH}/activity/activity-registration-report`,
  },
};
