import AxiosInstance from "./axios";

export const AxiosPost = async (URL, body) => {
  try {
    return await AxiosInstance.post(URL, body);
  } catch (error) {
    return error.data && error.response.data.message ? error.response.data.message : error.message;
  }
};

export const AxiosPut = async (URL, body) => {
  try {
    return await AxiosInstance.put(URL, body);
  } catch (error) {
    return error;
  }
};

export const AxiosGet = async (URL) => {
  try {
    return await AxiosInstance.get(URL);
  } catch (error) {
    return error;
  }
};

export const AxiosDelete = async (URL) => {
  try {
    return await AxiosInstance.delete(URL);
  } catch (error) {
    return error;
  }
};

export const AxiosPatch = async (URL, body) => {
  try {
    return await AxiosInstance.patch(URL, body);
  } catch (error) {
    return error;
  }
};
