import { toast } from "react-toastify";
import axios from "axios";

import { getToken, doLogout } from "../../Auth";

const instance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

instance.interceptors.request.use(
  function (config) {
    const token = getToken();
    config.headers["Authorization"] = token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response && (response.status === 200 || response.status === 201)) {
      return response.data;
    } else {
      if (response && (response.status === 401 || response.status === 408)) {
        toast.error("Session Expire.");
      } else if (response && response.status >= 500) {
        toast.error("Internal Server Error.", { toastId: "error", autoClose: 2000 });
      } else {
        toast.error(response.message, {
          toastId: "response_data",
          autoClose: 2000,
        });
      }
    }
  },
  function (error) {
    if (error.response?.data) {
      if (error.response.data?.status === 401 || error.response.data?.status === 408) {
        toast.error(error.response.data?.message, { toastId: "nodata", autoClose: 1000 });
        setTimeout(() => {
          doLogout();
          window?.location?.replace("/");
        }, 1000);
      } else if (error.response.data?.status === 404) {
        if (!toast.isActive("nodata")) {
          toast.error(error.response.data?.message, { toastId: "nodata", autoClose: 1000 });
        } else {
          toast.update("nodata", {
            autoClose: 2000,
            render: error.response.data?.message,
            type: toast.TYPE.ERROR,
          });
        }
      } else {
        toast.error(error.response?.data?.message, { toastId: "error_response", autoClose: 2000 });
      }
    } else {
      return Promise.reject(error.response);
    }
  }
);

export default instance;
