import { useNavigate } from "react-router-dom";

const Button = ({ text, data }) => {
  const navigate = useNavigate();

  return (
    <div className="block md:flex items-center gap-2">
      {text.map((tx, i) => {
        const { path, title, csv = false } = tx;
        const handleClick = () => {
          if (typeof path === "string") {
            return navigate(path);
          } else {
            path();
          }
        };

        return (
          <div key={`btn-${i}`}>
            {csv && csv !== undefined && data?.length > 0 && <div></div>}
            {title && (
              <button
                className={title && "btn-primary w-full px-4 text-sm md:text-base"}
                onClick={() => handleClick()}
              >
                {title}
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Button;
