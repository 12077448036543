import { thunk } from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./Reducers/index";
import { getReduxState, isLoggedIn, setReduxState } from "../Auth";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function saveToLocalStorage(state) {
  if (!isLoggedIn()) return;
  try {
    setReduxState(state);
  } catch (e) {
    console.warn(e);
  }
}

export function loadFromLocalStorage() {
  try {
    const serialisedState = getReduxState("state");
    return serialisedState;
  } catch (e) {
    return undefined;
  }
}

const store = createStore(
  rootReducer,
  loadFromLocalStorage(),
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
