const sysLightMode = window.matchMedia('(prefers-color-scheme: light)').matches;

const themeConfig = {
  app: {
    name: 'Crest'
  },
  layout: {
    darkMode: sysLightMode ? false : true,
    semiDarkMode: false,
    menu: {
      isCollapsed: false,
      isHidden: false
    },
    mobileMenu: false
  },
  breakpoints: {
    sm: '640',
    md: '768',
    lg: '1024',
    xl: '1280'
  }
};

export default themeConfig;
