import { Dots } from "react-activity";

function Loader({ size = 20, speed = 0.6 }) {
  return (
    <div className="text-blue-500 react-activity dark:text-slate-300">
      <Dots size={size} speed={speed} className="text-center" />
    </div>
  );
}

export default Loader;
