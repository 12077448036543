import { useState } from 'react';
import GlobalStateContext from './GlobalStateContext';

const GlobalStateProvider = ({ children }) => {
  const [globalBoolean, setGlobalBoolean] = useState(false);

  return (
    <GlobalStateContext.Provider value={{ globalBoolean, setGlobalBoolean }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
