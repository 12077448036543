import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import GlobalStateProvider from "./Components/ContextApi/GlobalStateProvider.jsx";

import "./assets/style.css";
import store from "./Redux/store.jsx";
import reportWebVitals from "./reportWebVitals";
import "./assets/scss/app.scss";
import "simplebar-react/dist/simplebar.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-activity/dist/library.css";
import "react-datepicker/dist/react-datepicker.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Suspense fallback={""}>
      <BrowserRouter>
        <GlobalStateProvider>
          <App />
        </GlobalStateProvider>
      </BrowserRouter>
    </Suspense>
  </Provider>
);

reportWebVitals();
