import React from "react";
import { BsSortDownAlt, BsSortUpAlt } from "react-icons/bs";

import Loader from "./Loader";
import SearchInput from "../../Components/Forms/SearchInput";
import Button from "../../Components/Forms/Button";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";

const CustomTable = ({
  darkMode,
  headerData,
  tableData,
  onSortData,
  tableFilter,
  setTableFilter,
  showRecords = true,
  showPagination = true,
  isAssign = false,
  isSearch = true,
  assignHandle,
  addBtnText,
}) => {
  const [searchParams] = useSearchParams();
  const { isLoading, error, totalCount, data } = tableData;
  const { pageIndex, pageSize, sortBy, sortDirection } = tableFilter;
  if (error) {
    return <p>Error</p>;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <div>
        <div className="grid grid-cols-1 gap-5 pb-5 sm:grid-cols-3 lg:grid-cols-2 ">
          <div className="flex items-center">
            {showRecords && totalCount > 10 && (
              <div className="w-1/3 mr-5 sm:w-1/4 lg:w-1/2 xl:w-1/4 xl:py-0">
                <select
                  className={
                    (darkMode ? "background-linear-textfield " : "bg-white ") +
                    "form-select selectbox-text"
                  }
                  value={pageSize}
                  onChange={(e) => {
                    setTableFilter({
                      ...tableFilter,
                      pageIndex: 1,
                      pageSize: Number(e.target.value),
                    });
                  }}
                >
                  {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                    <option className="dark:bg-black-500" key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {isSearch && (
              <SearchInput
                value={searchParams.get("search")}
                onChange={(e) =>
                  setTableFilter({ ...tableFilter, search: e?.target?.value, pageIndex: 1 })
                }
              />
            )}
          </div>
          <div className="ml-auto col">
            {addBtnText && <Button text={addBtnText} data={data} />}
          </div>
        </div>

        <hr className="border-t-[1px] border-gray-900 pb-2 md:pb-0" />
        <div className="overflow-x-auto">
          <table className="table w-full mt-5 border border-gray-100 dark:border-slate-700">
            <thead className="text-sm border border-gray-100 text-black-600 dark:text-slate-300 dark:border-slate-700 bg-button-100">
              <tr className="w-full bg-button-100">
                {headerData?.map((item, i) => (
                  <th
                    className={`px-3 py-2 font-semibold text-left border-gray-300 dark:border-slate-800 bg-button-100 dark:bg-gradient-to-r from-slate-500 to-slate-700 ${item.className}`}
                    width={item?.width}
                    key={`${item?.name}+${i}`}
                    onClick={() => item?.filterable === true && onSortData(item?.name)}
                  >
                    {item?.header}
                    {item?.filterable === true && (
                      <span className="ml-1 cursor-pointer">
                        {sortBy === item?.name && sortDirection ? (
                          <BsSortDownAlt className="inline-block" />
                        ) : (
                          <BsSortUpAlt className="inline-block" />
                        )}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-sm">
              {data?.length > 0 ? (
                data.map((item, id) => (
                  <tr key={id}>
                    {headerData?.map((dataitem, i) => (
                      <td
                        className="w-auto px-3 py-2 border border-gray-100 border-x-0 dark:border-slate-900"
                        key={i}
                      >
                        {dataitem.value(item, id)}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={headerData?.length} className="px-3 py-2 text-center">
                    {" "}
                    No Records Found{" "}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {isAssign && (
          <div className="text-center">
            <button
              className="px-5 mt-4 uppercase btn btn-primary"
              type="button"
              onClick={assignHandle}
            >
              Assign
            </button>
          </div>
        )}

        {showPagination && data?.length > 0 && (
          <div className="flex flex-wrap items-center justify-center mt-4 mr-0 md:justify-end ">
            <div className="md:flex-[0_0_auto] md:w-auto">
              <div className="flex">
                <button
                  type="button"
                  onClick={() => setTableFilter({ ...tableFilter, pageIndex: 1 })}
                  disabled={pageIndex === 1 ? true : false}
                  className="px-3 py-2 mr-1 text-xs rounded btn-primary md:px-4"
                >
                  {"<<"}
                </button>
                <button
                  type="button"
                  onClick={() => setTableFilter({ ...tableFilter, pageIndex: pageIndex - 1 })}
                  disabled={pageIndex === 1 ? true : false}
                  className="px-3 py-2 text-xs rounded btn-primary md:px-4"
                >
                  {"<"}
                </button>
              </div>
            </div>
            <div className="md:flex-[0_0_auto] md:w-auto hidden md:block ml-1">
              <span>
                {" "}
                Page{" "}
                <strong>
                  {" "}
                  {pageIndex} of {Math.ceil(totalCount / pageSize)}
                </strong>{" "}
              </span>
            </div>

            <div className="md:flex-[0_0_auto] md:w-auto mx-2">
              <span>
                <input
                  type="number"
                  className="w-16 form-control sm:w-32 disabled:dark:text-slate-300"
                  disabled
                  value={pageIndex}
                  onChange={(e) => {
                    if (e.target.value && e.target.value > 0) {
                      const page = e.target.value
                        ? e.target.value <= Math.ceil(totalCount / pageSize)
                          ? Number(e.target.value)
                          : pageIndex
                        : 0;
                      setTableFilter({ ...tableFilter, pageIndex: page });
                    }
                  }}
                />
              </span>{" "}
            </div>

            <div className="md:flex-[0_0_auto] md:w-auto">
              <div className="flex">
                <button
                  type="button"
                  onClick={() => setTableFilter({ ...tableFilter, pageIndex: pageIndex + 1 })}
                  disabled={
                    totalCount === 0 || pageIndex === Math.ceil(totalCount / pageSize)
                      ? true
                      : false
                  }
                  className="px-3 py-2 mr-1 text-xs rounded btn-primary md:px-4"
                >
                  {">"}
                </button>
                <button
                  type="button"
                  onClick={() =>
                    setTableFilter({ ...tableFilter, pageIndex: Math.ceil(totalCount / pageSize) })
                  }
                  disabled={
                    totalCount === 0 || pageIndex === Math.ceil(totalCount / pageSize)
                      ? true
                      : false
                  }
                  className="px-3 py-2 text-xs rounded btn-primary md:px-4"
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = function (state) {
  return {
    darkMode: state.layout.darkMode,
  };
};

export default connect(mapStateToProps)(CustomTable);
