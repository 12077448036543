import { IoMdSearch } from 'react-icons/io';

const SearchInput = ({ onChange, value }) => (
  <div className='relative'>
    <input type='search' placeholder='Search' defaultValue={value}
      className='hover:ring-green-400 focus:ring-green-400  border-none bg-[#F4F7FE] dark:bg-gradient-to-r from-slate-500 to-slate-700 rounded-full pl-8 lg:pl-12' onChange={onChange} />
    <IoMdSearch size={20} className='absolute top-2.5 lg:top-3 left-2 lg:left-4' />
  </div>
);

export default SearchInput;