import { lazy, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { getCurrentUserDetail, isLoggedIn } from "./Auth";
import { ToastContainer } from "react-toastify";
import { addSidebarData } from "./Redux/Actions";
import GlobalStateContext from "./Components/ContextApi/GlobalStateContext";
import User from "./pages/Admin/StudentDetails";

const Login = lazy(() => import("./pages/Auth/Login"));
const Users = lazy(() => import("./pages/Admin/Users"));
const Activity = lazy(() => import("./pages/Admin/Activity"));
const Question = lazy(() => import("./pages/Admin/Question"));
const Feedback = lazy(() => import("./pages/Admin/Feedback"));
const Volunteer = lazy(() => import("./pages/Admin/Volunteer"));
const Student = lazy(() => import("./pages/Admin/Student"));
const ViewStudent = lazy(() => import("./pages/Admin/Student/Model/ViewStudent"));
const Games = lazy(() => import("./pages/Admin/Games"));
const ActivityAgeMaster = lazy(() => import("./pages/Admin/ActivityAgeMaster"));
const ActivityZoneDateMaster = lazy(() => import("./pages/Admin/ActivityZoneDateMaster"));
const ActivityEntryFeeMaster = lazy(() => import("./pages/Admin/ActivityEntryFeeMaster"));

const ActivityZoneSessionMaster = lazy(() => import("./pages/Admin/ActivityZoneSessionMaster"));

const GameZoneRegisterReport = lazy(() =>
  import("./pages/Admin/GameZoneRegisterReport/GameZoneRegisterReport")
);

const GameZoneReport = lazy(() => import("./pages/Admin/GameZoneReport/GameZoneReport"));
const ActivityReport = lazy(() => import("./pages/Admin/ActivityReport/ActivityReport"));

const Dashboard = lazy(() => import("./pages/Admin/Dashboard"));
const Profile = lazy(() => import("./pages/Auth/Profile"));

const DashboardLayout = lazy(() => import("./pages/common/DashboardLayout"));
const PageNotFound = lazy(() => import("./pages/common/PageNotFound"));

function App() {
  const userData = getCurrentUserDetail();

  const { globalBoolean } = useContext(GlobalStateContext);

  useEffect(() => {
    isLoggedIn();
  }, [globalBoolean]);

  return (
    <>
      {isLoggedIn() ? (
        <Routes>
          {userData?.userRole === "SuperAdmin" ? (
            <Route path="/" element={<Users />}></Route>
          ) : (
            <Route path="/" element={<DashboardLayout />}>
              <Route path="/" element={<Activity />} />
              <Route path="/image-video-upload" element={<Dashboard />} />
              <Route path="/games" element={<Games />} />
              <Route path="/activity-age" element={<ActivityAgeMaster />} />
              <Route path="/game-zone-date" element={<ActivityZoneDateMaster />} />
              <Route path="/activity-zone-session-master" element={<ActivityZoneSessionMaster />} />
              <Route path="/activity-entry-fee" element={<ActivityEntryFeeMaster />} />
              <Route path="/game-zone-register-report" element={<GameZoneRegisterReport />} />
              <Route path="/game-zone-report" element={<GameZoneReport />} />

              <Route path="/activity-report" element={<ActivityReport />} />

              <Route path="/student" element={<Student />} />
              <Route path="/volunteer" element={<Volunteer />} />
              <Route path="/user-list" element={<User />} />
              <Route path="/question" element={<Question />} />
              <Route path="/student/:id" element={<ViewStudent />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/profile" element={<Profile />} />
            </Route>
          )}
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Navigate to={"/login"} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<Navigate to={"/"} />} />
        </Routes>
      )}

      <ToastContainer position="top-right" theme="colored" autoClose="2000" />
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    menuItems: state.MenuItems,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    updateSidebar: (data) => dispatch(addSidebarData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
