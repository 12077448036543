import { useEffect, useMemo, useState } from "react";
import CustomTable from "../../common/CustomTable";
import { useSearchParams } from "react-router-dom";
import { AxiosGet } from "../../../Components/Common/axiosService";
import { API_URLS } from "../../../Components/Common/constants";
import { toast } from "react-toastify";

const User = () => {
  document.title = "User";

  let [searchParams, setSearchParams] = useSearchParams();
  const query = Object?.fromEntries(searchParams?.entries());

  const [status, setStatus] = useState(query?.status || "User");

  const [tableFilter, setTableFilter] = useState({
    pageSize: 10,
    pageIndex: 1,
    sortBy: "",
    sortDirection: true,
    search: query?.search ?? "",
    updateData: true,
  });
  const [tableData, setTableData] = useState({
    data: [],
    totalCount: 0,
    isSuccess: false,
    error: "",
    isLoading: true,
  });

  const { pageIndex, pageSize, sortBy, sortDirection, search, updateData } = tableFilter;

  useEffect(() => {
    if (search) {
      const timer = setTimeout(() => {
        fetchUserList(pageIndex, sortBy, search, pageSize, sortDirection);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (sortBy) {
      fetchUserList(pageIndex, sortBy, search, pageSize, sortDirection);
    } else {
      fetchUserList(pageIndex, sortBy, search, pageSize, sortDirection);
    }
  }, [pageIndex, sortBy, search, pageSize, sortDirection, updateData, status]);

  const fetchUserList = async (page, sort, searchValue, limit, direction) => {
    let searchStr = "";

    searchParams.delete("page");
    searchParams.delete("pagesize");
    searchParams.delete("search");
    searchParams.delete("designationId");

    if (page > 0) searchParams.set("page", page);
    searchParams.set("pagesize", limit);
    if (sort) searchStr = `&sort=${sort}&sortDirection=${direction ? "ASC" : "DESC"}`;
    if (search) {
      searchParams.set("search", search);
      searchStr = searchStr ? searchStr + `&search=${searchValue}` : `&search=${searchValue}`;
    }

    setSearchParams(searchParams);
    try {
      const response = await AxiosGet(
        `${API_URLS.USER.USER_LIST}?userRole=${status}&page=${page}&limit=${limit}${
          searchStr ? searchStr : ""
        }`
      );
      if (response && response.status === 200) {
        const filterUser = response?.payload;
        setTableData({
          ...tableData,
          isSuccess: true,
          totalCount: response?.count,
          data: filterUser,
          isLoading: false,
        });
      } else {
        setTableData({ ...tableData, isSuccess: false, isLoading: false });
      }
    } catch (error) {
      setTableData({ ...tableData, isSuccess: false, isLoading: false });
      toast.error("Something Went Wrong", { toastId: "nodata", autoClose: 2000 });
    }
  };

  const Columns = useMemo(() => {
    const commonColumns = [
      {
        header: "#",
        name: "index",
        filterable: false,
        value: function (cell, i) {
          return (
            <span>{`${
              (pageIndex === 1 && pageIndex > 0 ? 0 : pageIndex - 1) * pageSize + (i + 1)
            }`}</span>
          );
        },
        width: "5%",
      },
      {
        header: "Name",
        name: "firstName",
        filterable: true,
        value: (cell) => <span className="capitalize">{cell?.firstName ?? "-"}</span>,
      },
      // {
      //   header: "Last Name",
      //   name: "lastName",
      //   filterable: true,
      //   value: (cell) => <span className="capitalize">{cell?.lastName ?? "-"}</span>,
      // },
      {
        header: "Phone Number",
        name: "phoneNumber",
        filterable: true,
        value: (cell) => <span>{cell?.phoneNumber || "-"}</span>,
      },
      {
        header: "Date of Birth",
        name: "don",
        filterable: true,
        value: (cell) => <span>{cell?.dob || "-"}</span>,
      },
      {
        header: "City",
        name: "city",
        filterable: true,
        value: (cell) => <span>{cell?.city || "-"}</span>,
      },
    ];
    return commonColumns;
  }, [pageIndex, pageSize, updateData]);

  const onSortData = (value) => {
    setTableFilter({
      ...tableFilter,
      pageIndex: 1,
      sortDirection: value === sortBy ? !sortDirection : true,
      sortBy: value,
    });
  };

  return (
    <>
      <div className="p-1 md:p-4">
        <div className="p-5 mb-5 bg-white border border-gray-900 rounded-lg dark:bg-slate-700">
          <div className="grid w-full md:grid-cols-3 xl:grid-cols-4 gap-x-2">
            <div className="relative w-full mb-1">
              <select
                className={"form-select selectbox-text bg-white"}
                name="status"
                value={status}
                onChange={(event) => {
                  setStatus(event.target.value);
                  setTableFilter({ ...tableFilter, pageIndex: 1 });
                }}
              >
                <option className="dark:bg-black-500" hidden>
                  Select User Role
                </option>
                <option className="dark:bg-black-500" value="User">
                  Student
                </option>
                <option className="dark:bg-black-500" value="Volunteer">
                  Volunteer
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="p-5 bg-white border border-gray-900 rounded-lg dark:bg-slate-700">
          <div className="w-full">
            <CustomTable
              tableData={tableData}
              headerData={Columns}
              onSortData={onSortData}
              tableFilter={tableFilter}
              setTableFilter={setTableFilter}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
