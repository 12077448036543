import { createSlice } from "@reduxjs/toolkit";

import themeConfig from "../../configs/themeConfig";

const initialSidebarCollapsed = () => {
  const item = window?.localStorage.getItem("sidebarCollapsed");
  return item ? JSON.parse(item) : themeConfig?.layout.menu.isCollapsed;
};

const initialState = {
  isCollapsed: initialSidebarCollapsed(),
  mobileMenu: themeConfig?.layout.mobileMenu,
  semiDarkMode: themeConfig?.layout.semiDarkMode,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    handleSidebarCollapsed: (state, action) => {
      state.isCollapsed = action.payload;
      window?.localStorage.setItem("sidebarCollapsed", action.payload);
    },
    handleMobileMenu: (state, action) => {
      state.mobileMenu = action.payload;
    },
  },
});

export const { handleDarkMode, handleSidebarCollapsed, handleMobileMenu } = layoutSlice.actions;

export default layoutSlice.reducer;
